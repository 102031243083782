/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container } from 'react-bootstrap';


const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

export default ({ children }) => (
  <Container>
  <Row>
  <Col>
    <header style={{ marginBottom: `1rem` }}>
      <Link to="/" style={{ textShadow: `none`, backgroundImage: `none` }}>
        <h1 style={{ display: `inline` }}>MacroBuddy</h1>
      </Link>
      <ul style={{float: `right`, marginBottom: 0, marginTop: '1rem'}}>
        <ListLink to="/">Home</ListLink>
        <ListLink to="/about/">About</ListLink>
      </ul>
    </header>
    {children}
    </Col>
    </Row>
  </Container>
)